<template>
	<TempEmailForm :id="id" :is-update="isUpdate" @submit="update" @cancel="cancel" />
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { GET_TEMP_EMAIL, UPDATE_TEMP_EMAIL } from "../store/action-types"
import { RESET_EMAIL_FILTERS } from "../store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("email")
export default {
	name: "UpdateTempEmail",
	components: {
		TempEmailForm: () => import("../components/TempEmailForm"),
	},
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.getTemplateDetail()
	},
	beforeDestroy() {
		this.RESET_EMAIL_FILTERS()
	},
	methods: {
		...mapActions({ GET_TEMP_EMAIL, UPDATE_TEMP_EMAIL }),
		...mapMutations({ RESET_EMAIL_FILTERS }),
		async getTemplateDetail() {
			const params = {
				id: this.id,
			}
			await this.GET_TEMP_EMAIL(params)
		},
		async update(params) {
			await this.UPDATE_TEMP_EMAIL(params)
		},
		cancel() {
			this.$router.push({ name: "EmailManagement" })
		},
	},
}
</script>

<style></style>
